import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
import { useMixPanel } from '../../../../contexts/mixpanel'
import { NovaColours, useAppContext } from '@novafuturltd/core'
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuSMSProviderSwitch: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  const { mode } = useAppContext()
  const defaultFill = NovaColours.N060[mode]
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="smsproviderswitch"
        data-test="sms_provider_switch_sidebar_menuitem"
        component={ButtonLink}
        href="/campaign-mgmt/sms-provider-switch"
        selected={
          pathname.substring(0, 24) === '/campaign-mgmt/sms-provider-switch'
        }
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Campaign-mgmt: SMS Provider switch)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon data-test="campaign_sms_provider_switch_sidemenu_icon">
          <SmsTwoToneIcon fill={defaultFill} />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.campaign-mgmt.smsproviderswitch',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
